<template>
  <div>
    <v-dialog max-width="1280" v-model="showDialog">
      <v-card style="overflow-x: hidden">
        <v-row class="pl-3 pr-3 mt-0">
          <v-card-title class="ml-3 mb-3">{{
            $t("Patient.SearchPatient")
          }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="handleCreatePatientClick"
            class="mt-3 mr-3 pa-2"
            style="float: right"
            color="primary"
          >
            <v-icon dark class="mr-2"> mdi-plus </v-icon>
            {{ $t("Patient.Create") }}
          </v-btn>
        </v-row>
        <div class="pl-3 pr-3">
          <SearchPatientOptions
            v-model="searchOptions"
            @input="renderPatientList"
          />
        </div>
        <v-sheet class="pa-4">
          <v-data-table
            :headers="list.headers"
            :items="list.items"
            :options.sync="list.options"
            :loading="list.loading"
            :server-items-length="list.totals"
            :footer-props="{
              tableFooterProps,
              'items-per-page-text': $t('Common.RowsPerPage'),
            }"
            @update:items-per-page="renderPatientList"
            @update:page="onChangePagination"
            disable-sort
            class="app-custom-table"
            mobile-breakpoint="0"
            :item-class="colorSelectedRow"
          >
            <template v-slot:[`item.fullName`]="{ item }">
              <v-avatar size="32px" color="primary">
                <img v-if="item.avatar" alt="Avatar" :src="item.avatar" />
                <span v-else class="white--text">
                  {{ getNameInnitial(item.fullName) }}
                </span>
              </v-avatar>
              <a class="ml-2" @click="choosePatient(item)">{{
                item.fullName
              }}</a>
            </template>
            <template v-slot:[`item.genderType`]="{ item }">
              <GenderItem :genderType="item.genderType" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="viewPatientProfile(item.patientID)"
                icon
                class="mr-10"
              >
                <v-icon> mdi-eye-outline </v-icon>
              </v-btn>
              <v-btn @click="choosePatient(item)" icon color="primary">
                <v-icon> mdi-arrow-right-bold-circle-outline </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              {{ $t("EmptyData.SearchPatient") }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-dialog>
    <PatientProfilePopup
      ref="PatientProfilePopup"
      @onCreateSuccess="handleCreatePatientSuccess"
    />
  </div>
</template>

<script>
import GenderItem from "../../../components/commonComponents/Queue/components/GenderItem";
import { TABLE_ROWS_PER_PAGE } from "../../../plugins/constant";
import PatientService from "../../../services/patient";
import {
  getStatusCodeOptions,
  getNameInnitial,
  getStatusColor,
  getStatusName,
  sleep,
} from "../../../plugins/helper";
import PatientProfilePopup from "../PatientInfo/PatientProfilePopup";
import SearchPatientOptions from "@/components/commonComponents/PatientRegistration/SearchPatientOptions";

import moment from "moment";
import i18n from "@/plugins/i18n";

export default {
  components: {
    GenderItem,
    PatientProfilePopup,
    SearchPatientOptions,
  },
  computed: {
    statusCodes: () => getStatusCodeOptions(),
  },
  data: () => {
    return {
      showDialog: false,
      tableFooterProps: {
        itemsPerPageOptions: TABLE_ROWS_PER_PAGE,
      },
      searchOptions: {
        keySearch: "",
        idSearch: "",
        aidSearch: "",
        phoneSearch: "",
        dobSearch: "",
        addressSearch: "",
      },
      list: {
        headers: [
          { text: i18n.t("Patient.FullName"), value: "fullName" },
          { text: i18n.t("Patient.Gender"), value: "genderType" },
          { text: i18n.t("Patient.DOB"), value: "dob" },
          { text: i18n.t("Patient.Phone"), value: "phoneNumber" },
          { text: i18n.t("Common.Address"), value: "address", width: "30%" },
          { text: "", value: "actions", sortable: false },
        ],
        totals: 0,
        items: [],
        loading: true,
        options: {
          itemsPerPage: 15,
          keySearch: "",
          statusID: 1,
        },
      },
      status: {
        type: "error",
        show: false,
        message: "",
      },
    };
  },
  async mounted() {
    await this.renderPatientList();
  },
  methods: {
    getNameInnitial,
    getStatusColor,
    getStatusName,
    showError(message) {
      this.$toast.error(message);
    },
    async open() {
      // clean options
      this.list.totals = 0;
      this.list.items = [];
      this.list.options.keySearch = "";
      this.list.options.statusID = 1;
      // open popup
      this.showDialog = true;
      await sleep(100);
    },
    viewPatientProfile(patientID) {
      this.$refs.PatientProfilePopup.openPopup(patientID);
    },
    handleCreatePatientClick() {
      this.$refs.PatientProfilePopup.openCreate();
    },
    async renderPatientList() {
      const { page, itemsPerPage } = this.list.options;
      const {
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
      } = this.searchOptions;
      this.list.loading = true;
      var result = await PatientService.searchPatients(
        keySearch,
        idSearch,
        aidSearch,
        phoneSearch,
        dobSearch,
        addressSearch,
        page,
        itemsPerPage
      );
      this.list.loading = false;
      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }
      result.items.forEach((item) => {
        item.phoneNumber = item.phoneNumber || "N/A";
        item.email = item.email || "N/A";
        item.address = item.address || "N/A";
        item.statusID = this.list.options.statusID;
        item.dob = moment(item.dob).format("DD-MM-YYYY");
      });
      this.list.items = result.items;
      this.list.totals = result.totals;
    },
    async onChangePagination(value) {
      this.list.options.page = value;
      await this.renderPatientList();
    },
    choosePatient({ patientID, fullName }) {
      // emit selected item outside
      this.$emit("onPatientSelected", { patientID, fullName });
      // close popup
      this.showDialog = false;
    },
    handleCreatePatientSuccess(patientID) {
      var { fullName } = this.$refs.PatientProfilePopup.personalInfo;
      this.choosePatient({ patientID, fullName });
    },
    colorSelectedRow(item) {
      return item.patientID == this.patientID ? "grey lighten-2" : "";
    },
  },
};
</script>