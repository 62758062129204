<template>
    <div class='d-block w-100 h-auto'>
        <div class="d-flex flex-column pl-4 mb-3">
            <div class="w-100 d-flex align-center">
                <span class="text-subtitle-2">Total Bookings: </span>
                <b class="text-body-2 ml-3 font-weight-bold primary--text">{{ totalBooking }}</b>
            </div>
        </div>

        <v-sheet>
           
            <!-- Calendar -->
            <v-calendar
                ref="calendar"
                color="primary"
                v-model="focus"
                :type="type"
                :events="events"
                :event-color="getEventColor"
                @click:event="showEvent"
                @click:date="viewDay"
                @click:more="viewDay"
                @change="updateRange"
                @click:time="startTimeForClick"

                @mousedown:event="startDrag"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mousedown:time="startTime"
                @mouseleave.native="cancelDrag"
                
            >
                <template #event="{event}" class="itemEvent">
                    <ItemEventBooking>
                        <span v-if="event.detailBooking">Clinic: <b>{{ event.detailBooking.clinicName }}</b></span>
                        <span>Visitor: <b>{{ event.name }}</b></span>
                    </ItemEventBooking>
                </template>
            </v-calendar>
           
            <!-- Detail Booking -->  
            <v-menu
                v-model="selectedOpen"
                :activator="selectedElement"
                :close-on-content-click="false"
                offset-x
                max-width="600px"
                min-width="250px"
                >   
                <v-card
                    max-width="600px"
                    min-width="250px"
                    flat
                    >
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                        >
                        <v-avatar
                            size="30"
                            title
                            color="#ECEFF1"
                            class="mr-3"
                        >
                            <span class="black--text text-caption font-weight-bold">
                                {{ getShortName }}
                            </span>
                        </v-avatar>
                        <!-- View detail patient -->
                        <v-tooltip right>                         
                            <template v-slot:activator="{ on, attrs }">
                                <v-toolbar-title 
                                    v-bind="attrs"
                                    v-on="on"
                                    style="font-size: 16px;"
                                    >
                                    {{ selectedEvent.name }}
                                    <v-icon class="ml-1" x-small>
                                        mdi-cursor-pointer
                                    </v-icon>
                                </v-toolbar-title>
                            </template>
                            <!-- Short Detail patient information -->
                            <PatientDetail v-if="!emptySelectedEvent">
                                <PatientDetailItem>
                                    <PatientDetailItemTitle>
                                        <v-icon small>
                                            mdi-phone
                                        </v-icon>
                                    </PatientDetailItemTitle>
                                    <b>{{ selectedEvent.detailBooking.phone }}</b>
                                </PatientDetailItem>
                                <PatientDetailItem>
                                    <PatientDetailItemTitle>
                                        <v-icon small>
                                            mdi-email
                                        </v-icon>
                                    </PatientDetailItemTitle>
                                    <b>{{ selectedEvent.detailBooking.email }}</b>
                                </PatientDetailItem>
                                <PatientDetailItem>
                                    <PatientDetailItemTitle>
                                        <v-icon small>
                                            mdi-gender-male-female
                                        </v-icon>
                                    </PatientDetailItemTitle>
                                    <b>{{ genderType }}</b>
                                </PatientDetailItem>
                                <PatientDetailItem>
                                    <PatientDetailItemTitle>
                                        <v-icon small>
                                            mdi-account-question-outline
                                        </v-icon>
                                    </PatientDetailItemTitle>
                                    <b>{{ getAge }} Ages</b>
                                </PatientDetailItem>
                            </PatientDetail>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <ContentBooking 
                            v-if="!isEmtpySelectedEvent"
                            :bookingID="selectedEvent.bookingID"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            text
                            color="secondary"
                            @click="selectedOpen = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="removeLoading"
                            text
                            color="error"
                            @click="removeItemBooking"
                        >
                            Remove
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu> 
        </v-sheet>  
    </div>
</template>

<script>
import { getNameInnitial } from '@/plugins/helper'
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from "moment";
import _ from 'lodash';
import ContentBooking from './ContentBooking';
import { 
    ItemEventBooking,
    PatientDetail,
    PatientDetailItem,
    PatientDetailItemTitle
    } from './css/style';

export default {
    name: 'BookingCalendar',
    components: {
        ContentBooking,
        ItemEventBooking,
        PatientDetail,
        PatientDetailItem,
        PatientDetailItemTitle
    },
    computed: {
        ...mapState('BookingCalendar', {
            type: 'type',
            clinicID: 'clinicID',
            keySearch: 'keySearch',
            events: 'events',
            defaultRangeTime: 'defaultRangeTime',
            timeStart: 'timeStart',
            timeEnd: 'timeEnd',
            removeLoading: 'removeLoading',
            status_req: 'status_req'
        }),
        totalBooking() {
            return this.events.length;
        },
        getShortName() {
            if (!_.isEmpty(this.selectedEvent)) {
                let shortName = getNameInnitial(this.selectedEvent.name);
                return shortName;
            } else {
                return ''
            }
        },
        emptySelectedEvent() {
            return _.isEmpty(this.selectedEvent)
        },
        genderType() {
            return (this.selectedEvent.detailBooking.genderType) ? 'Male' : 'Female'
        },
        getAge() {
            let currentYear = new Date().getFullYear();
            let birthYear = moment(this.selectedEvent.detailBooking.dob).format('YYYY');
            return currentYear - birthYear
        },
        isEmtpySelectedEvent() {
            return _.isEmpty(this.selectedEvent);
        }
    },
   
    watch: {
        type() {   
            setTimeout(() => {
                 this.SET_TITLE_CALENDAR({title: this.$refs.calendar.title})  
            }, 10)           
        },
        events() {
            this.selectedOpen = false;
            this.selectedEvent = {};
            this.selectedElement = null;
            
        },
        status_req: {
            deep: true,
            handler() {
                if (this.status_req.error) {
                    this.$toast.error(this.status_req.message);
                    return;
                }
            }
        }
    },

    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'BookingCalendar/SET_EMPTY_FOCUS') {
                this.focus = '';
                return;
            }
            if (mutation.type === 'BookingCalendar/NEXT_TIME') {
                if (typeof this.$refs.calendar === 'undefined') {
                    return;
                }
                // fire event next of calendar
                this.$refs.calendar.next();
                this.setTitle(); 
                return;      
            } 
            if (mutation.type === 'BookingCalendar/PREV_TIME') {
                if (typeof this.$refs.calendar === 'undefined') {
                    return;
                }
                this.$refs.calendar.prev();
                this.setTitle();
                return;
            }  
        })
        
    },

    mounted() {  
        this.SET_TITLE_CALENDAR({title: this.$refs.calendar.title}) 
    },  

    data: () => ({
        typeTime: null,
        focus: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,

        // event to create a booking by mouse
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        moveBooking: false,
        // store OldStartTime and OldEndTime
        // use this to revert time when update fail
        originalStart: null,
        originalEnd: null
    }),

    methods: {
        ...mapMutations('BookingCalendar', [
            'SET_TITLE_CALENDAR', 
            'SET_TYPE',
            'SET_TIME',
            'PUSH_EVENT',
            'OPEN_DIALOG',
            'SET_NEW_BOOKING',
            'SET_TimeForCreate',
        ]),
        ...mapActions('BookingCalendar', [
            'GET_BOOKING',
            'REMOVE_ITEM_BOOKING',
            'UPDATE_BOOKING'
        ]),
        getEventColor(event) {
            return event.color;
        },
        viewDay({ date }) {
            this.focus = date;
            this.SET_TYPE({type: 'day'})
        },

        showEvent({ nativeEvent, event}) { 
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true;
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false;
                setTimeout(open, 10)
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },

        setTitle() {
            setTimeout(() => {
                 this.SET_TITLE_CALENDAR({title: this.$refs.calendar.title})
            }, 10)  
        },
        updateRange({start, end}) {
            let timeStart = `${start.date} 00:00:00`;
            let timeEnd  = `${end.date} 23:59:59`; 
            //this.searchBooking(timeStart, timeEnd);
            this.GET_BOOKING({timeStart, timeEnd})
        },

        // ----------------- Event to create a booking ------------------
        startTimeForClick(tms) {
            const mouse = this.toTime(tms);
            this.createStart = mouse
            this.SET_NEW_BOOKING({ createNew: true })
            this.SET_TimeForCreate({
                timeStart: moment(this.createStart).format('HH:mm'),
                dateVisit: moment(tms.date).format('DD/MM/YYYY')
            })
            this.createEvent = { 
                name: `Order booking`,
                clinicName:'Clinic',
                bookingID: null, // with null we can filter them to reload list event if use choose the cancel creating new booking
                color: 'red',
                start: this.createStart,
                end: this.createStart + this.defaultRangeTime,
                timed: true
            }
            this.OPEN_DIALOG();
            this.PUSH_EVENT({event: this.createEvent});     
        },
        startTime(tms) {
            const mouse = this.toTime(tms);
            
            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;
                this.dragTime = mouse - start;
            }
        },
        toTime(tms) {
            let { hour, minute } = this.formatTimeStart(tms)
            return new Date(tms.year, tms.month - 1, tms.day, hour, minute).getTime();
        },
      
        formatTimeStart(tms) {
            let {hour, minute} = tms;
            
            if (minute < 15) {
                minute = 0;
            }
            if (minute < 45 && minute > 15) {
                minute = 30;
            }
            if (minute >= 45) {
                minute = 0;
                hour++;
            }       
            return {hour, minute};
        },

        // ------------------ Remove Item booking ------------------------
        removeItemBooking() {
            let bookingID = this.selectedEvent.bookingID;
            this.REMOVE_ITEM_BOOKING({bookingID});
        },


        // ------------------ Drag & drop ------------------------
        startDrag({ event, timed}) {
            
            if (event && timed) {
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;
                // store timeStart & timeEnd to restore display data when update fail
                this.originalStart = event.start;
                this.originalEnd = event.end;
            }
        },
        mouseMove(tms) {
            const mouse = this.toTime(tms);
            
            if (this.dragEvent && this.dragTime != null) {
                let start = this.dragEvent.start;
                let end = this.dragEvent.end;
                const duration = end - start;
                const newStartTime = mouse - this.dragTime; // ==> Number
               
                const newStart = new Date(newStartTime).getTime();
                const newEnd = newStart + duration;
             
                // update new time 
                this.dragEvent.start = newStart;
                this.dragEvent.end = newEnd;
               
                // if real moving booking item <==> start != newStartTime
                start = new Date(start).getTime();  // ==> Number
                if (start != newStartTime) {
                    this.moveBooking = true;
                }
            }
        },
        endDrag() {
            if (this.dragEvent !== null && this.moveBooking == true) {
                // call business here 
                let {bookingID, start, end}  = this.dragEvent;
                let timeStart = moment(start).format('YYYY-MM-DD HH:mm:ss');
                let timeEnd = moment(end).format('YYYY-MM-DD HH:mm:ss');
                let originalStart = this.originalStart;
                let originalEnd = this.originalEnd;

                /// update booking 
                this.UPDATE_BOOKING({
                    timeStart: timeStart, 
                    timeEnd: timeEnd, 
                    bookingID: bookingID,
                    originalStart: originalStart,
                    originalEnd: originalEnd
                });
                // set default moving booking item 
                this.moveBooking = false;
                this.originalStart = null;
                this.originalEnd = null;
            }
            
            this.dragTime = null;
            this.dragEvent = null;
            this.createEvent = null;
            this.createStart = null;
            this.extendOriginal = null;
        },
        cancelDrag() {
            this.createEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
        }
    }
}
</script>

<style scoped>
    .v-toolbar {
        padding: 5 10px;
    }
</style>