<template >
  <v-container class="pa-0 ma-0">
    <v-form ref="createBookingForm">
      <v-row class="pl-4 pr-4">
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="patientID"
            :items="patients"
            :rules="requiredRules"
            @click="openPopupSearchingPatient"
            filled
            hide-no-data
            hide-selected
            hide-details="true"
            :label="$t('Patient.SelectPatient')"
            append-icon="mdi-account-box-outline"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4"
          ><v-autocomplete
            v-model="clinicID"
            :rules="requiredRules"
            :items="clinics"
            @change="renderListServices"
            filled
            append-icon="mdi-account-box-outline"
            :label="$t('Clinic.SelectClinic')"
          ></v-autocomplete
        ></v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="serviceID"
            :rules="requiredRules"
            :items="services"
            filled
            :label="$t('Service.SelectService')"
            append-icon="mdi-clipboard-plus-outline"
          ></v-autocomplete
        ></v-col>
      </v-row>
      <PatientInformation v-show="patientID" :patientID="patientID" />
      <CreateBookingInformation
        v-model="booking"
        v-bind="$props"
        ref="BookingInformationForm"
        @duplicate-booking-timeslot="checkEmptyTimeSlotBooking"
      />
      <!-- Error when time slot has bookings already-->
      <v-row v-if="duplicateTimeSlotBooking">
        <v-col cols="12">
          <div class="d-flex w-100 align-center pl-4 pr-4">
            <v-icon small color="warning" class="mr-1"> mdi-alert </v-icon>
            <span class="warning-text" style="color: #fb9513"
              >Take care! Time slot has bookings already!</span
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="pl-3 pr-3 mt-3" justify="center">
      <v-btn width="150px" @click="onCancelCreate" text>
        {{ $t("Button.Cancel") }}
      </v-btn>
      <v-btn
        :loading="isCreateNewBooking"
        width="150px"
        @click="createNewBooking"
        color="primary"
      >
        {{ $t("Button.Create") }}
      </v-btn>
    </v-row>
    <PopupSearchingPatient
      ref="PopupSearchingPatient"
      @onPatientSelected="handlePatientSelected"
    />
  </v-container>
</template>

<script>
import PatientInformation from "../PatientInfo/PatientInformation";
import CreateBookingInformation from "./CreateBookingInformation";
import PopupSearchingPatient from "../PatientRegistration/PopupSearchingPatient";

import ServiceService from "../../../services/service";
import BookingService from "../../../services/booking";
import BrandService from "../../../services/brand";

import { requiredRules } from "../../../plugins/rules";
import moment from "moment";

export default {
  components: {
    CreateBookingInformation,
    PatientInformation,
    PopupSearchingPatient,
  },
  props: {
    timeStart: {
      // use this to pass to CreateBookingInformation
      type: String,
      default: "",
    },
    dateVisit: {
      // use this to pass to CreateBookingInformation
      type: String,
      default: "",
    },
  },
  data() {
    return {
      requiredRules,
      isCreateNewBooking: false,
      status: {
        type: "error",
        show: false,
        message: "",
      },
      patients: [],
      services: [],
      clinics: [],
      patientID: null,
      serviceID: null,
      clinicID: null,
      booking: {},
      // duplicate timeslot
      duplicateTimeSlotBooking: false,
    };
  },
  computed: {},
  watch: {
    clinicID() {
      // call dom BookingInformationForm to insert clinicID
      this.$refs.BookingInformationForm.setClinicID(this.clinicID);
    },
  },
  async created() {
    this.renderListClinics();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    openPopupSearchingPatient() {
      this.$refs.PopupSearchingPatient.open();
    },
    // handle patient item selected from PatientPopupSearch
    handlePatientSelected({ patientID, fullName }) {
      // re-render the auto-complete box
      this.patients = [{ text: fullName, value: patientID }];
      // then hightlight the selection
      this.patientID = patientID;
    },
    async createNewBooking() {
      if (!this.$refs.createBookingForm.validate()) {
        this.showError(this.$t("Toast.MissingRequiredInformation"));
        return;
      }
      if (!moment(this.booking.timeStart).isAfter(moment().add(1, "hours"))) {
        this.showError(this.$t("Toast.MinimumBooking"));
        return;
      }
      this.isCreateNewBooking = true;
      const bookingData = {
        clinicID: this.clinicID,
        serviceID: this.serviceID,
        patientID: this.patientID,
        timeStart: this.booking.timeStart,
        timeEnd: this.booking.timeEnd,
        chiefCompain: this.booking.chiefCompain,
        systemicConditions: this.booking.systemicConditions,
        bookingType: this.booking.bookingType,
        additionalInfos: this.booking.additionalInfos,
      };

      // case duplicateTimeSlot
      if (this.duplicateTimeSlotBooking) {
        this.$swal({
          title: "Are you sure?",
          text: "This time-slot has bookings already! You wanna to create?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I'm sure!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.sendRequestToCreateBooking(bookingData);

            return;
          }
          this.isCreateNewBooking = false;
          return;
        });
        return;
      }
      //
      this.sendRequestToCreateBooking(bookingData);
    },

    async sendRequestToCreateBooking(bookingData) {
      var result = await BookingService.create(bookingData);
      this.isCreateNewBooking = false;
      if (result.error) {
        this.showError("Cannot create new booking. Please try again later.");
        return;
      }
      this.$emit("onCreateSuccess", result);
      // clean the form
      this.cleanForm();
    },

    async renderListClinics() {
      const result = await BrandService.searchBrandClinics(1, 100);
      if (result.error) {
        this.showError("Can not get list clinics. Please try again later.");
        return;
      }
      result.items = result.items.reverse();
      this.clinics = result.items.map((i) => ({
        value: i.clinicID,
        text: i.clinicName,
      }));
      if (this.clinics.length) {
        this.clinicID = this.clinics[0].value;
        // set clinicID for CreateBookingInformation Component
        this.$refs.BookingInformationForm.setClinicID(this.clinicID);
      }
      this.renderListServices();
    },
    async renderListServices() {
      if (!this.clinicID) return;
      const result = await ServiceService.searchAvailableByClicnicID(
        this.clinicID
      );
      if (result.error) {
        this.showError("Can not get list services. Please try again later.");
        return;
      }
      this.services = result.items.map((i) => ({
        value: i.id,
        text: i.description,
      }));
    },
    cleanForm() {
      this.serviceID = null;
      this.patientID = null;
      this.patients = [];
      this.booking = {};
      this.$refs.BookingInformationForm.cleanForm();
    },
    onCancelCreate() {
      this.$emit("onCancelCreate", true);
    },
    checkEmptyTimeSlotBooking(emptyOrNot) {
      this.duplicateTimeSlotBooking = emptyOrNot;
    },
  },
};
</script>

<style scoped>
.row > div,
.col > div {
  padding-bottom: 0px !important;
}
</style>