<template>
    <div class="d-flex flex-column">
        <v-row class="pl-4 pr-4">
            <v-col xl="3" lg="3" md="4" sx="12" sm="12"> 
                <v-select
                    v-model="clinicData.selection"
                    :items="clinicData.items"
                    :error="clinicData.error"
                    :error_message="clinicData.error_message"
                    :loading="clinicData.loading"
                    item-value="value"
                    item-text="text"
                    label="Clinic"
                    placeholder="My clinic"                 
                >
                </v-select>   
            </v-col> 

            <v-col xl="3" lg="3" md="4" sx="12" sm="12">
                <v-text-field
                    v-model="keySearch"
                    label="Enter to search"
                    append-icon="mdi-account-search-outline"
                    placeholder="Searching"
                    @keydown.enter="searching"
                    @keyup.delete="removeKey"
                ></v-text-field>
            </v-col> 
            <v-spacer></v-spacer>
            <v-col xl="3" lg="3" md="4" sx="12" sm="12"> 
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="900px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            ref="btnOpenDialog"
                            small 
                            v-bind="attrs"
                            v-on="on"
                            class="mt-3 mr-3 pa-2 d-flex align-center"
                            style="float: right"
                            color="primary">
                                <v-icon dark class="mr-2"> mdi-plus </v-icon> new booking
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h6">New Booking</span>
                        </v-card-title>
                        <v-card-text>
                            <div class="d-flex justify-center align-center">
                                  <CreateBookingForm 
                                    :timeStart="timeStartForCreate"
                                    :dateVisit="dateVisitForCreate"
                                    @onCancelCreate="cancelCreateNewBooking"
                                    @onCreateSuccess="handleCreateBookingSuccess"
                                  />
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>      
            </v-col>
        </v-row>
        <v-toolbar
            flat
            >
            <!-- Today -->
            <v-btn
                outlined 
                class="mr-4"
                color="grey darken-2"
                @click="setToDay"
                small
            >
            Today
            </v-btn>

            <!-- Prev -->
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
            >
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
            </v-btn>

            <!-- Next -->
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
            >
                <v-icon small>
                mdi-chevron-right
                </v-icon>
            </v-btn>    
            <v-toolbar-title style="font-size: 16px;">
                {{ titleCalendar }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu
                bottom
                right
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                        small
                    >
                        <span>{{ typeToLabel }}</span>
                    
                        <v-icon right>
                            mdi-menu-down
                        </v-icon>
                    </v-btn>
                </template> 
                <v-list>
                    <v-list-item 
                        v-for="(item, key) in listDefaultType" 
                        :key="key"
                        @click="SET_TYPE({type: item.value})"
                        >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>               
            </v-menu>
        </v-toolbar>
    </div>
  
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import BrandService from "@/services/brand";
import CreateBookingForm from "@/components/commonComponents/OnlineBooking/CreateBookingForm";

export default {
    name: 'ToolBarCalendar',
    
    components: {
        CreateBookingForm
    },

    computed: {
        ...mapState('BookingCalendar', [
            'listDefaultType',
            'typeToLabel', 
            'type',
            'titleCalendar',
            'createNewBooking',
            'timeStartForCreate',
            'dateVisitForCreate',
            'timeStart',
            'timeEnd'
        ])
    },
    watch: {
        'clinicData.selection' : {
            deep: true,
            handler() {
                this.SET_CLINIC({clinicID: this.clinicData.selection})
                this.updateEventsForCalendar();
            }
        },
        keySearch() {
            this.SET_KEYSEARCH({keySearch: this.keySearch})
        }
    },

    data: () => ({
        dialog: false,
        keySearch: '',
        clinicData: {
            selection: "",
            items: [],
            loading: false,
            error: false,
            error_message: '',
        },
    }),
    created() {
        this.searchingClinic(),
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'BookingCalendar/OPEN_DIALOG') {
                if (this.createNewBooking) {
                    this.dialog = true;
                }
            } 
        })
    },
    methods: {
        handleCreateBookingSuccess() {
            this.dialog = false;
            this.$toast.success("Create a new booking successfully!");
            this.updateEventsForCalendar();
        },
        async searchingClinic() {
            this.clinicData.loading = true;
            // refresh error
            this.clinicData.error = false;
            let result = await BrandService.searchBrandClinics();
            if (result.error) {
                this.clinicData.error = true;
                this.clinicData.loading = false;
                this.clinicData.error_message = 'Can not load list clinic!';
                return;
            }
            this.clinicData.loading = false;
            this.clinicData.items = result.items.map(i => {
                return {text: i.clinicName, value: i.clinicID}
            })
            this.clinicData.items.unshift({
                text: "AllClinics",
                value: "",
            });
        },
        searching() {
            this.SET_KEYSEARCH({keySearch: this.keySearch});
            this.updateEventsForCalendar();
        },
        removeKey() {
            if (this.keySearch.length == 0) {
                this.SET_KEYSEARCH({keySearch: this.keySearch});
                this.updateEventsForCalendar();
            }
        },
        setToDay () {
            this.SET_EMPTY_FOCUS()
        },

        next() {
            this.NEXT_TIME();
        },

        prev() {
            this.PREV_TIME();
        },
        cancelCreateNewBooking() {
            this.dialog = false;
            // condition here 
            // if creation from click a time on the calendar => set to default state
            if (this.createNewBooking) {
                this.SET_TimeForCreate({timeStart: '', dateVisit: ''});
                this.SET_NEW_BOOKING({createNew: false});
                this.REMOVE_NEW_ORDER_BOOKING();
            }
        },
        updateEventsForCalendar() {
            let timeStart = this.timeStart;
            let timeEnd = this.timeEnd;
            this.GET_BOOKING({timeStart, timeEnd})
        },
        ...mapMutations('BookingCalendar',[
            'SET_TYPE',
            'SET_EMPTY_FOCUS',
            'NEXT_TIME',
            'PREV_TIME',
            'SET_CLINIC',
            'SET_KEYSEARCH',
            'LOAD_CALENDAR',
            'SET_NEW_BOOKING',
            'SET_TimeForCreate',
            'REMOVE_NEW_ORDER_BOOKING'
        ]),
        ...mapActions('BookingCalendar', [
            'GET_BOOKING'
        ])
        
    }
}
</script>

<style>

</style>