<template>
    <!-- Show Error if having -->
    <v-alert
        class="mt-2"
        :type="type || 'error' "
        icon="mdi-cloud-alert"
        dismissible
        v-model="showAlert"
        transition="scale-transition"
    >
        {{ message }}
    </v-alert>
</template>

<script>
export default {
    props: {
        type: {
            type: String
        },
        icon: {
            type: String,
            default: 'mdi-cloud-alert'
        },
        show: {
            type: Boolean,
            default: false
        },
        message: {
            type: String, 
            default: ''
        }
    },
    data: () => {
        return {
            showAlert: false
        }
    },

    watch: {
        show() {
            this.showAlert = this.show
        },
        showAlert() {
            this.$emit('input', this.showAlert);
        }
        
    },

    created() {
         this.showAlert = this.show
    },
    
}
</script>

<style>

</style>