<template>
  <v-row class="pl-4 pr-4 pb-5">
    <v-col cols="12" md="4" class="pt-0 mt-0">
      <v-menu
        ref="menuDatePicker"
        v-model="menuDatePicker"
        :close-on-content-click="false" 
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :rules="dateFormatedRules"
            @change="onChange"
            :label="$t('Booking.BookingDate')"
            v-mask="'##/##/####'"
            placeholder="dd/mm/yyyy"
            append-icon="mdi-calendar-outline"
            filled
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datePicker"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuDatePicker = false"
          >
            {{ $t('Button.Cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="saveDatePicker"
          >
            {{ $t('Button.Accept') }}
          </v-btn>
        </v-date-picker>
      </v-menu>


      
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mt-0">
      <v-autocomplete
        v-model="time"
        @change="onChange"
        :rules="requiredRules"
        :items="TimeStringsOptions"
        :label="$t('Booking.BookingTime')"
        placeholder="hh:mm"
        v-mask="'##:##'"
        append-icon="mdi-clock-time-four-outline"
        filled
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mt-0">
      <v-select
        v-model="selections.admissionType"
        :rules="requiredRules"
        :items="options.admissionTypes"
        @change="onChange"
        filled
        :label="$t('Admission.AdmissionType')"
        append-icon="mdi-plus-outline"
      ></v-select>
    </v-col>
    <v-col cols="12" class="pt-0 mt-0">
      <v-textarea
        v-model="chiefCompain"
        @input="onChange"
        filled
        :label="$t('Common.ChiefComplains')"
        append-icon="mdi-message-text-outline"
        rows="3"
      ></v-textarea>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mb-5">
      <v-combobox
        v-model="selections.chronicDiseases"
        :items="options.chronicDiseases"
        @change="onChange"
        hide-details
        class="ma-0 mb-1"
        :label="$t('Common.ChronicDiseases')"
        multiple
        filled
        dense
      ></v-combobox>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mb-5">
      <v-combobox
        v-model="selections.allergies"
        :items="options.allergies"
        @change="onChange"
        hide-details
        class="ma-0 mb-1"
        :label="$t('Common.Allergies')"
        multiple
        filled
        dense
      ></v-combobox>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mb-5">
      <v-combobox
        v-model="selections.personalBehaviour"
        :items="options.personalBehaviour"
        @change="onChange"
        hide-details
        class="ma-0 mb-1"
        :label="$t('Common.PersonalBehaviour')"
        multiple
        filled
        dense
      ></v-combobox>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mb-5">
      <v-combobox
        v-model="selections.familyHistory"
        :items="options.familyHistory"
        @change="onChange"
        hide-details
        class="ma-0 mb-1"
        :label="$t('Common.FamilyHistory')"
        multiple
        filled
        dense
      ></v-combobox>
    </v-col>
    <v-col cols="12" md="4" class="pt-0 mb-5">
      <v-combobox
        v-model="selections.pastHistory"
        :items="options.pastHistory"
        @change="onChange"
        hide-details
        class="ma-0 mb-1"
        :label="$t('Common.PersonalHistory')"
        multiple
        filled
        dense
      ></v-combobox>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import LookupService from "../../../services/lookup";
import EntitiesService from "../../../services/entities";
import { dateFormatedRules, requiredRules } from "../../../plugins/rules";
import BookingService from "@/services/booking";
import {
  BookingAdditionalStatType,
  TimeStringsOptions,
} from "../../../plugins/constant";

export default {
  props: {
    value: {
      type: Object,
    },
    timeStart: {
      // using this on Calendar component, we click on the calendar, this props is passed by CreateBookingForm
      type: String,
      default: "",
    },
    dateVisit: {
      // using this on Calendar component, we click on the calendar, this props is passed by CreateBookingForm
      type: String,
      default: "",
    },
  },
  watch: {
    timeStart() {
      this.mapPropsTimeStart();
    },
    dateVisit() {
      this.mapPropsDateTime();
    },
    clinicID() {
      this.emptyBookingTimeSlot();
    },
    time() {
      this.emptyBookingTimeSlot();
    },
    date() {
      this.emptyBookingTimeSlot();
    }
  },
  data: () => ({
    dateFormatedRules,
    requiredRules,
    TimeStringsOptions,
    menuDatePicker: false,
    options: {
      admissionTypes: [],
      chronicDiseases: [],
      allergies: [],
      personalBehaviour: [],
      pastHistory: [],
      familyHistory: [],
    },
    selections: {
      admissionType: "",
      chronicDiseases: [],
      allergies: [],
      personalBehaviour: [],
      pastHistory: [],
      familyHistory: [],
    },
    chiefCompain: "",
    time: moment().startOf("hour").add(2, "hours").format("HH:mm"),
    date: moment().format("DD/MM/YYYY"),
    datePicker: moment().format("YYYY-MM-DD"),
    clinicID: null,
    duplicateBooking: false
  }),
  async created() {
    this.renderListChronicDiseases();
    this.renderListAllergies();
    this.renderListFamilyHistories();
    this.renderListPastHistories();
    this.renderListPersonalBehaviour();

    await this.renderListAdmissionTypes();
    // emit default value back to parent
    this.onChange();
  },
  mounted() {
    this.mapPropsTimeStart();
    this.mapPropsDateTime();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    cleanForm() {
      this.time = moment().startOf("hour").add(2, "hours").format("HH:mm");
      this.date = moment().format("DD/MM/YYYY");
      this.chiefCompain = "";
      this.onChange();
    },
    prepareData() {
      if (!this.time || !this.date) return;
      var dateBooking = moment(`${this.date}`, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      var timeBooking = moment(`${this.time}`, "HH:mm").format("HH:mm");
      var dateTimeBooking = `${dateBooking} ${timeBooking}`;
      var additionalInfos = [];
      this.selections.allergies.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.Allergy,
        })
      );
      this.selections.chronicDiseases.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.ChronicDisease,
        })
      );
      this.selections.personalBehaviour.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.PersonalBehaviour,
        })
      );
      this.selections.pastHistory.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.PastHistory,
        })
      );
      this.selections.familyHistory.forEach((i) =>
        additionalInfos.push({
          targetStatID: i.value,
          targetStatType: BookingAdditionalStatType.FamilyHistory,
        })
      );
      var data = {
        additionalInfos,
        systemicConditions: "",
        timeStart: dateTimeBooking,
        timeEnd: dateTimeBooking,
        chiefCompain: this.chiefCompain,
        bookingType: this.selections.admissionType,
      };
      return data;
    },
    onChange() {
      let data = this.prepareData();
      this.$emit("input", data);
    },
    mapPropsTimeStart() {
      this.time =
        this.timeStart != ""
          ? this.timeStart
          : moment().startOf("hour").add(2, "hours").format("HH:mm");
      if (this.timeStart != "") {
        let data = this.prepareData();
        this.$emit("input", data);
      }
    },
    mapPropsDateTime() {
      this.date =
        this.dateVisit != "" ? this.dateVisit : moment().format("DD/MM/YYYY");
      if (this.dateVisit != "") {
        let data = this.prepareData();
        this.$emit("input", data);
      }
    },
    async renderListAdmissionTypes() {
      var result = await LookupService.searchAdmissionTypes();
      if (result.error) {
        this.showError(
          "Can not get list admission types. Please try again later."
        );
        return;
      }
      this.options.admissionTypes = result.items.map((i) => ({
        ...i,
        text: i.codeDescription,
        value: i.admissionTypeID,
      }));
      this.selections.admissionType =
        this.options.admissionTypes[0] &&
        this.options.admissionTypes[0].admissionTypeID;
    },
    async renderListChronicDiseases() {
      var result = await EntitiesService.listChronicDisease("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list chronic diseases. Please try again later."
        );
        return;
      }
      this.options.chronicDiseases = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListAllergies() {
      var result = await EntitiesService.listAllergy("", 1, 10000);
      if (result.error) {
        this.showError("Can not get list allergies. Please try again later.");
        return;
      }
      this.options.allergies = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListFamilyHistories() {
      var result = await EntitiesService.listFamilyHistory("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list family histories. Please try again later."
        );
        return;
      }
      this.options.familyHistory = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListPastHistories() {
      var result = await EntitiesService.listPastHistory("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list past histories. Please try again later."
        );
        return;
      }
      this.options.pastHistory = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },
    async renderListPersonalBehaviour() {
      var result = await EntitiesService.listPersonalBehaviour("", 1, 10000);
      if (result.error) {
        this.showError(
          "Can not get list personal behaviour. Please try again later."
        );
        return;
      }
      this.options.personalBehaviour = result.items.map((i) => ({
        ...i,
        text: i.description,
        value: i.id,
      }));
    },

    // set ID from Parent 
    setClinicID(clinicID) {
      this.clinicID = clinicID;
    },  
    // check duplication booking event
    async emptyBookingTimeSlot() {
      if (this.clinicID == null) return;
      let dateAndTimeStart = `${this.date} ${this.time}:00`;
      let dateTimeFormat = encodeURIComponent(
          moment(dateAndTimeStart, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss Z")
      );
      //call bookingService to call API
      var emptyTimeSlot = await BookingService.checkBookingWithTimeSlot(
        this.clinicID,
        dateTimeFormat
      );      
      if (emptyTimeSlot.error) {
        return;
      }
      this.$emit("duplicate-booking-timeslot", emptyTimeSlot)
    },

    saveDatePicker() {
      this.$refs.menuDatePicker.save(this.datePicker)
      // convert
      this.date = moment(this.datePicker, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
  },
};
</script>
<style scoped>
.row > div,
.col > div {
  padding-bottom: 0px !important;
}
</style>