<template>
    <v-main class="pt-0 pl-2 pr-2" light>
        <v-container fluid>
            <div class="w-100">
                <v-card elevation="0" outlined>
                    <v-card-title class="d-flex align-center">
                        <v-btn 
                            small 
                            icon 
                            class="mr-2"
                            color="primary"
                            @click="goBackBooking"
                            >
                            <v-icon>
                                mdi-arrow-left-thick
                            </v-icon>
                        </v-btn>
                        <span class="text-h6">Booking Calendar</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="fill-height">
                                <ToolbarCalendar ref="toolbar"/>
                                <Calendar />
                            </v-col>
                        </v-row>  
                    </v-card-text>      
                </v-card>
            </div>
        </v-container>
    </v-main>
</template>

<script>
import { mapMutations } from 'vuex'
import ToolbarCalendar from './components/ToolbarCalendar';
import Calendar from './components/Calendar';
export default {
    components: {
        ToolbarCalendar,
        Calendar,   
    },
    methods: {
        ...mapMutations('BookingCalendar', ['SET_DEFAULT_STATE']),
        goBackBooking() {
            this.SET_DEFAULT_STATE()
            this.$router.push({ path: "/onlinebooking"})
        }
    },
}
</script>

<style>

</style>