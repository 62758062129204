var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1280"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-row',{staticClass:"pl-3 pr-3 mt-0"},[_c('v-card-title',{staticClass:"ml-3 mb-3"},[_vm._v(_vm._s(_vm.$t("Patient.SearchPatient")))]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 mr-3 pa-2",staticStyle:{"float":"right"},attrs:{"color":"primary"},on:{"click":_vm.handleCreatePatientClick}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("Patient.Create"))+" ")],1)],1),_c('div',{staticClass:"pl-3 pr-3"},[_c('SearchPatientOptions',{on:{"input":_vm.renderPatientList},model:{value:(_vm.searchOptions),callback:function ($$v) {_vm.searchOptions=$$v},expression:"searchOptions"}})],1),_c('v-sheet',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"app-custom-table",attrs:{"headers":_vm.list.headers,"items":_vm.list.items,"options":_vm.list.options,"loading":_vm.list.loading,"server-items-length":_vm.list.totals,"footer-props":{
            tableFooterProps: _vm.tableFooterProps,
            'items-per-page-text': _vm.$t('Common.RowsPerPage'),
          },"disable-sort":"","mobile-breakpoint":"0","item-class":_vm.colorSelectedRow},on:{"update:options":function($event){return _vm.$set(_vm.list, "options", $event)},"update:items-per-page":_vm.renderPatientList,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"32px","color":"primary"}},[(item.avatar)?_c('img',{attrs:{"alt":"Avatar","src":item.avatar}}):_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.getNameInnitial(item.fullName))+" ")])]),_c('a',{staticClass:"ml-2",on:{"click":function($event){return _vm.choosePatient(item)}}},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.genderType",fn:function(ref){
          var item = ref.item;
return [_c('GenderItem',{attrs:{"genderType":item.genderType}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mr-10",attrs:{"icon":""},on:{"click":function($event){return _vm.viewPatientProfile(item.patientID)}}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.choosePatient(item)}}},[_c('v-icon',[_vm._v(" mdi-arrow-right-bold-circle-outline ")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("EmptyData.SearchPatient"))+" ")]},proxy:true}],null,true)})],1)],1)],1),_c('PatientProfilePopup',{ref:"PatientProfilePopup",on:{"onCreateSuccess":_vm.handleCreatePatientSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }