<template>
  <v-row class="pt-0 pl-3 pr-3 d-print-none" justify-space-between>
    <v-col cols="12" md="3" class="pl-6">
      <div class="text-center">
        <div class="text-center">
          <v-avatar color="grey lighten-2" size="140">
            <v-img
              v-if="personalInfo.avatar"
              :src="personalInfo.avatar"
            ></v-img>
            <v-icon v-else dark size="120px"> mdi-image-outline </v-icon>
          </v-avatar>
        </div>
      </div>
    </v-col>

    <v-col cols="12" md="8" class="pt-5 pl-8">
      <v-skeleton-loader
        :loading="isLoadingProfile"
        type="list-item-two-line, list-item-two-line, list-item-two-line"
      >
        <p class="ma-0 mb-2 text-space">
          {{ $t("Patient.PID") }}: <b>{{ patientIDString }}</b>
          <span v-if="personalInfo.athenaID" class="ml-10">
            {{ $t("Patient.AID") }}: <b>{{ personalInfo.athenaID }}</b>
          </span>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Common.FullName") }}: <b>{{ personalInfo.fullName }} </b>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Patient.Gender") }}:
          <b
            >{{
              personalInfo.genderType == 1
                ? $t("Common.Male")
                : $t("Common.Female")
            }}
          </b>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Patient.DOB") }}:
          <b>{{ formatDate(personalInfo.birthDate) }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Patient.Phone") }}: <b>{{ personalInfo.phone }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Common.Address") }}: <b>{{ personalInfo.address }}</b>
        </p>
        <p class="ma-0 mb-2 text-space">
          {{ $t("Common.Email") }}: <b>{{ personalInfo.email }}</b>
        </p>
      </v-skeleton-loader>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn
        v-if="patientID"
        @click="showPatientProfilePopup"
        icon
        color="blue-grey"
        class="mt-15 white--text btnIcon__editPatient"
      >
        <v-icon color="primary"> mdi-eye-outline </v-icon>
      </v-btn>

      <v-btn
        class="btnBlock__editPatient"
        block
        v-if="patientID"
        @click="showPatientProfilePopup"
        color="primary mt-4"
      >
        {{ $t("Button.Edit") }}
      </v-btn>
    </v-col>

    <PatientProfilePopup class="d-print-none" ref="PatientProfilePopup" />
  </v-row>
</template>

<script>
import moment from "moment";
import PatientService from "../../../services/patient";
import PatientProfilePopup from "./PatientProfilePopup";
import { getGenderStatusCodeOptions } from "../../../plugins/helper";

export default {
  props: {
    patientID: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    patientIDString: "NA",
    isLoadingProfile: true,
    status: {
      type: "error",
      show: false,
      message: "",
    },
    personalInfo: {},
  }),
  components: {
    PatientProfilePopup,
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
  },
  watch: {
    patientID: {
      handler(val) {
        this.renderPatientDetail(val);
      },
    },
  },
  async mounted() {},
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    showError(message) {
      this.$toast.error(message);
    },
    async renderPatientDetail(patientID) {
      if (!patientID) {
        return;
      }
      this.isLoadingProfile = true;
      var result = await PatientService.getPatientDetail(patientID);
      this.isLoadingProfile = false;
      if (result.error) {
        this.showError("Can not get clinic detail. Please try again later.");
        return;
      }
      const { patientIDString, personalInfo } = result;
      const { fullName } = personalInfo;
      personalInfo.fullName = fullName;
      this.personalInfo = personalInfo;
      this.patientIDString = patientIDString;
    },
    showPatientProfilePopup() {
      this.$refs.PatientProfilePopup.openPopup(this.patientID);
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.btnBlock__editPatient {
  display: none;
}
@media screen and (max-width: 750px) {
  .btnBlock__editPatient {
    display: block;
  }
  .btnIcon__editPatient {
    display: none;
  }
}
</style>