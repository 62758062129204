<template>
    <div class="d-block w-100">
        <div class="d-flex align-items justify-center w-100" 
            v-if="loading">
            <v-progress-circular
                class="ma-5"
                indeterminate
                color="primary"
                ></v-progress-circular>
        </div>
        <v-card elevation="0" v-if="!loading">
            <v-card-text class="pa-0" v-if="!emptyBookingDetail">
                <div class="d-flex flex-column">

                    <label class="item__info">
                        <v-icon small class="mr-1">
                            mdi-hospital-building
                        </v-icon>
                        <span class="title__booking">Clinic: </span>
                        <b class="primary--text">{{ bookingDetail.clinicName }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Type: </span>
                        <b>{{ bookingDetail.bookingTypeName }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Chief Complains:</span>
                        <b>{{ bookingDetail.chiefCompain }}</b>
                    </label>
                    <label class="item__info"> 
                        <span class="title__booking">Systemic Conditions:</span>
                        <b>{{ bookingDetail.systemicConditions }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Service: </span>
                        <b>{{ bookingDetail.serviceName }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Date: </span>
                        <b class="red--text">{{ date }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Time Start: </span>
                        <b class="red--text">{{ startHour }}</b>
                    </label>
                    <label class="item__info">
                        <span class="title__booking">Time End:</span>
                        <b class="red--text">{{ endHour }}</b>
                    </label>
                </div>
            </v-card-text>
            <BaseAlert 
                class="mt-1"
                :type="status.type"
                :show="status.show"
                :message="status.message"
            />
        </v-card> 

        <BaseAlert
            v-model="status.show"
            :type="status.type"
            :message="status.message"
            :show="status.show"
        /> 
    </div>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import BookingService from '@/services/booking';
import moment from "moment";
import _ from 'lodash';
export default {
    name: 'ContentBookingEvent',
    components: {
        BaseAlert
    },
    props: {
        bookingID: {
            type: Number,
            required: true,
        },
    },
    computed: {
        date() {
            if (!_.isEmpty(this.bookingDetail)) {
                return moment(this.bookingDetail.timeStart).format('ll')
            }
            return '';
        },
        startHour() {
            if (!_.isEmpty(this.bookingDetail)) {
                return moment(this.bookingDetail.timeStart).format('hh:mm:ss a');
            }
            return ''
        },
        endHour() {
            if (!_.isEmpty(this.bookingDetail)) {
                return moment(this.bookingDetail.timeStart).add(1, 'hours').format('hh:mm:ss a');
            }
            return ''
        },
        emptyBookingDetail() {
            return _.isEmpty(this.bookingDetail);
        }
    },
    watch: {
        bookingID() {
            this.getBookingDetail();      
        }
    },
    mounted() {
        this.getBookingDetail();     
    },

    data: () => ({
        bookingDetail: {},
        patientInfo: {},
        loading: false,
        status: {
            show: false,
            message: '',
            type: 'error'
        }
    }),

    methods: {
        showError(msg = '', type = 'error') {
            this.status = { type: type, message: msg, show: true}
        },
        async getBookingDetail() {
            if (!this.bookingID) {
                return;
            }
            this.loading = true;
            var result = await BookingService.getBookingDetail(this.bookingID);
            if (result.error) {
                this.loading = false;
                this.showError('Can not retrieve booking item!');
                return;
            }
            this.loading = false;
            this.bookingDetail = result;
        }
    }
}
</script>

<style scoped>
    .title__booking {
        margin-right: 10px;
    }
    .item__info {
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }
</style>