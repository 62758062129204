import styled from 'vue-styled-components';

export const ItemEventBooking = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PatientDetail = styled.div`
    display: flex;
    flex-direction: column;
    background-color: '#ffffff';
`;

export const PatientDetailItem = styled.label`
    margin: 5px 0px;
    display: flex;
    align-items: center;
`;

export const PatientDetailItemTitle = styled.span`
    margin-right: 20px;
`;